
body {
    margin: 0!important;
    padding: 0!important;
}

h1 {
    color: red;
}

.question-text {
    color: #6c757d;
}

.toast {
    max-width: 900px;
}

.toast-body span {
    cursor : pointer;
}

.toast-body {
    padding-left : 0;
    padding-right : 0;
    padding-top: 0;
}

div.container-fluid {
    margin: 0;
    padding: 0;
    max-width: none;
}
div.col-12 {
    max-width: 100%;
    padding: 0;
}

div.row {
    margin: 0;
}

.table td {
    vertical-align: middle;
    padding: 0 15px 0 5px;

    line-height: 1em;
    font-size: 20px;
    font-weight: bold;
}

td:nth-of-type(2n+1),
td:nth-of-type(2){
    font-size: 15px;
    font-weight: bold;
}
